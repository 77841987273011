import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

export default () => {
  const authors = useBlogAuthors()

  return (
    <>
      <Seo title='Our Team' />
      <Divider />
      <Stack effect='fadeInDown'>
        <PageTitle
          header='Team Members'
          subheader='We at Zenvava work together as a community to deliver high quality Zen decors.
          You can enjoy beautiful Oriental arts you have never seen before.
          Our goal is to spread new Zen culture around the world and let more people feel Zen.'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </>
  )
}
